<template>
  <v-select
      v-if="!first_create"
      v-model="selectedProject"
      class="mx-4"
      density="compact"
      flat
      hide-details
      return-object
      :items="projects"
      :disabled="loading"
      :loading="loading"
      item-title="name"
      item-value="id"
      :placeholder="$t('projects.select_project')"
      :no-data-text="$t('projects.without_project')"
  >
  </v-select>
  <v-btn
      v-else
      small
      color="success"
      class="mx-4"
      :to="{ name: 'projects'}"
  >
    <v-icon>mdi-plus</v-icon>
    {{ $t('projects.create_project') }}
  </v-btn>
</template>

<script>
import { EventBus } from "@/utils/EventBus";
import ProjectService from '@/services/ProjectService';

export default {
  name: "UserProjectsSelector",
  created() {
    this.initial();
    this.bindEvents();
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters['user/isLoggedIn'];
    },
    selectedProject: {
      get() {
        return this.$store.getters['project/getUserSelectedProject'] || this.$t('general.loading_process')
      },
      set(value) {
        if (value) {
          this.$store.dispatch('project/setProject', value);
        }
      }
    }
  },
  data() {
    return {
      page: 0,
      loading: false,
      first_create: true,
      projects: [],
      totalPages: 0,
    }
  },
  watch: {
    isLoggedIn(value) {
      if (value) {
        this.initial();
      } else {
        this.page = 0;
        this.projects = [];
        this.totalPages = 0;
      }
    },
  },
  methods: {
    initial() {
      if (this.isLoggedIn) {
        this.first_create = false;
        this.getUserProjects();
      }
    },
    getUserProjects() {
      this.page = 1;
      this.loading = true;

      ProjectService.getUserProjects({
        page: this.page
      }).then(response => {
        this.totalPages = response.data.totalPages;
        this.projects = response.data.projects;

        if (this.projects.length > 0) {
          let selectedProject = this.$store.getters['project/getUserSelectedProject'];
          if (!selectedProject) {
            selectedProject = this.projects[0];
            this.$store.dispatch('project/setProject', selectedProject);
          }

          this.selectedProject = selectedProject || this.projects[0];
        } else {
          this.$store.dispatch('project/setProject', null);
          this.first_create = true;
        }
      }, error => {
        this.$notify(error.response.data.message, {type: 'error'});
        this.first_create = true;
      }).finally(() => {
        this.loading = false;
      });
    },
    bindEvents() {
      EventBus.on('create-first-project', function(){

        this.initial();
      }.bind(this));
      EventBus.on('reload-projects', function(){
        this.getUserProjects();
      }.bind(this));
      EventBus.on('logout', function(){
        this.$store.dispatch('project/setProject', null);
      }.bind(this));
    },
  }
}
</script>
