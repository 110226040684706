import { EventBus } from "@/utils/EventBus";

export default {
  namespaced: true,
  state: {
    user: {
      id: null,
      name: null,
      username: null,
      token: null,
    },
  },
  getters: {
    isLoggedIn: (state) => (state.user.token != null),
    getUser: (state) => state.user,
    getId: (state) => state.user.id,
    getToken: (state) => state.user.token,
  },
  mutations: {
    SET_USER(state, { userData, clear }) {
      state.user.id = !clear ? userData.id : null;
      state.user.token = !clear ? userData.token : null;
      state.user.name = !clear ? userData.name : null;
      state.user.username = !clear ? userData.username : null;
    },
    SET_USER_INFO(state, userData) {
      for (const key in userData) {
        if (state.user[key]) {
          state.user[key] = userData[key];
        }
      }
    },
  },
  actions: {
    login({ commit }, userData) {
      commit("SET_USER", { userData: userData, clear: false });
    },
    logout({ commit }) {
      commit("SET_USER", { userData: null, clear: true });
      EventBus.emit('logout');
    },
    setUserInfo({ commit }, userData) {
      commit("SET_USER_INFO", userData);
    },
  },
};
