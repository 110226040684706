<template>
  <transition>
    <div>
    <v-snackbar
      v-model="snackbar"
      multi-line
      timeout="-1"
    >
      {{ item.message }}
      <template v-slot:actions v-if="item.showActionBtn">
        <v-btn
          variant="text"
          :color="colors[item.type] || colors['default']"
          @click="handleActionClick(item)"
        >
          {{ item.actionBtnText }}
        </v-btn>
      </template>
    </v-snackbar>
    </div>
  </transition>
</template>

<script>
export default {
  name: "SnackbarNotify",
  data() {
    return {
      item: {},
      items: [],
      timeout: 0,
      color: '',
      multiLine: true,
      snackbar: false,
      options: {
        type: 'default',
        showActionBtn: true,
        actionBtnText: 'general.close',
        timeout: 5000,
      },
      colors: {
        success: 'success',
        info: 'info',
        warning: 'warning',
        error: 'error',
        default: 'primary',
      },
      delayTimeEnabled: false,
      showTimeDelay: 500
    }
  },
  watch: {
    items(value) {
      if (value.length === 0) {
        this.delayTimeEnabled = false;
        return;
      }

      setTimeout(() => {
        this.showSnackbar()
      }, !this.delayTimeEnabled ? 0 : this.showTimeDelay);
    }
  },
  methods: {
    showSnackbar: function () {
      if (this.snackbar) return false;
      this.item = this.items[0];
      this.snackbar = true;
      this.item.timeout = setTimeout(() => {
        this.closeSnackbar()
      }, this.item.timeout);
    },
    closeSnackbar: function () {
      this.snackbar = false;
      clearTimeout(this.item.timeout);
      this.item = {};
      this.items.shift();
      if (this.items.length > 0) {
        this.showSnackbar();
      }
    },
    addItem: function (message, options, action = false) {
      let item = {
        message: message,
        type: options.type || this.options.type,
        showActionBtn: (!options.showActionBtn && options.showActionBtn === false) ? options.showActionBtn : this.options.showActionBtn,
        actionBtnText: options.actionBtnText || this.$t(this.options.actionBtnText),
        clickOnAction: action,
        timeout: options.timeout || this.options.timeout,
      };

      this.items.push(item);

      this.delayTimeEnabled = (this.snackbar && this.items.length > 0);

      if (!this.delayTimeEnabled) {
        this.showSnackbar();
      }
    },
    handleActionClick: function (item) {
      if (item.clickOnAction) {
        item.clickOnAction();
      }

      this.closeSnackbar();
    }
  }
}
</script>
