import axios from "@/utils/Axios";

class LineChartWidgetService {
  getConnectedUsersStatistic(projectId, startDate, endDate) {
    return axios.get('/projects/stats/user/'+ projectId +'/connected', {
      params: {
        startDate: startDate,
        endDate: endDate
      }
    });
  }
}

export default new LineChartWidgetService();