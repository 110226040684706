import { EventBus } from "@/utils/EventBus";

export default function(general) {
  general.setPageTitle = (title) => {
    document.title = title;
  }

  general.setHeaderTitle = (title) => {
    EventBus.emit('set-main-header-title', {
      title: title,
    });
  }
}