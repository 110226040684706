<template>
  <v-main>
    <div class="pa-5">
      <Header />
      <router-view/>
    </div>
  </v-main>
</template>

<script>
import Header from "@/components/Layouts/Sections/MainHeaderLayout";

export default {
  name: 'MainLayout',
  components: {
    Header,
  }
}
</script>

