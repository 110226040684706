<template>
  <v-row>
    <v-col cols="12" md="4" class="ma-auto">
      <v-img
        :width="100"
        class="ma-auto mb-4"
        :src="require('@/assets/logo.png')"
      />
      <v-card class="pa-4" v-show="successCheck && !success">
        <div class="text-h5">{{ $t('user.reset_password') }}</div>
        <v-form ref="form">
          <p class="mb-5">{{ $t('user.messages.password_reset', { email: email }) }}</p>
          <v-text-field
              v-model="user.password"
              :label="$t('user.password')"
              prepend-icon="mdi-lock"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              :rules="[requiredRule, passwordRule]"
              autocomplete="one-time-code"
          />
          <v-text-field
              v-model="confirm"
              :label="$t('user.password_confirm')"
              prepend-icon="mdi-lock"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPasswordConfirm = !showPasswordConfirm"
              :rules="[requiredRule, passwordRule, passwordConfirmRule(user.password)]"
              autocomplete="one-time-code"
          />
          <div class="d-flex justify-space-around mb-4">
            <router-link :to="{ name: 'register' }">{{ $t('general.sign_up') }}</router-link>
            <router-link :to="{ name: 'login' }">{{ $t('general.sign_in') }}</router-link>
          </div>
          <div class="text-center">
            <v-btn
              @click="reset"
              :disabled="loading"
              :loading="loading"
              color="primary"
            >
              {{ $t('general.forgot_password_reset') }}
            </v-btn>
          </div>
        </v-form>
      </v-card>
      <v-card v-show="success">
        <v-alert
            border="start"
            variant="tonal"
            color="success"
            :title="$t('user.forgot_password')"
        >
          {{ $t('user.messages.password_reset_successfully') }}
          <p class="text-center">
            <router-link :to="{ name: 'login' }">
              {{ $t('general.login') }}
            </router-link>
          </p>
        </v-alert>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Validation from "@/plugins/Validation";
import AuthService from "@/services/AuthService";

export default {
  name: "ForgotPassword",
  mixins:[Validation],
  computed: {
    email() {
      return this.$route.query?.email;
    },
    token() {
      return this.$route.query?.token;
    }
  },
  mounted() {
    this.check();
  },
  data() {
    return {
      user: {
        email: null,
      },
      loading: false,
      success: false,
      successCheck: false,
      showPassword: false,
      showPasswordConfirm: false,
    }
  },
  methods: {
    async check() {
      if (!this.email || !this.token) {
        await this.$router.push({
          name: 'forgot'
        });
      }

      this.loading = true;

      AuthService.forgotCheck({
        email: this.email,
        token: this.token,
      }).then(() => {
        this.successCheck = true;
      }, error => {
        this.$notify(error.response.data.message, {type: 'error'});
      }).finally(() => {
        this.loading = false;
      })
    },
    async reset() {
      const {valid} = await this.$refs.form.validate();

      if (!valid) {
        return false;
      }

      this.loading = true;

      AuthService.forgotReset({
        email: this.email,
        token: this.token,
        password: this.user.password
      }).then(() => {
        this.success = true;
      }, error => {
        this.$notify(error.response.data.message, {type: 'error'});
      }).finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>
