import moment from "moment/moment";

export default {
  namespaced: true,
  state: {
    selectedProject: null,
  },
  getters: {
    isUserSelectProject: (state) => (state.selectedProject != null),
    getUserSelectedProject: (state) => state.selectedProject,
    getSelectedProjectId: (state) => (state.selectedProject != null)? state.selectedProject.id : 0,
    getSubscriptionStatus: (state) => {
        if (!state.selectedProject?.subscription?.id || state.selectedProject.subscription.status === 'canceled') {
          return false;
        }

        return moment.unix(state.selectedProject.subscription.end_date).diff(moment.now()) > 0;
    },
    getSubscriptionDate: (state) => {
      if (!state.selectedProject?.subscription?.id) {
        return false;
      }

      return moment.unix(state.selectedProject.subscription.end_date).format('DD MMMM YYYY');
    }
  },
  mutations: {
    SET_USER_SELECTED_PROJECT(state, { project }) {
      state.selectedProject = project;
    },

  },
  actions: {
    setProject({ commit }, project) {
      commit("SET_USER_SELECTED_PROJECT", { project: project });
    },
  },
};
