import axios from "axios";
import store from "@/store";
import router from "@/router";
import {EventBus} from "@/utils/EventBus";


const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    withCredentials: false,
    crossDomain: true,
    headers: {
        Accept: "application/json",
        "Content-type": "application/json",
    },
});

apiClient.interceptors.request.use(async (request) => {
    const isLoggedIn = store.getters["user/isLoggedIn"];
    const isApiUrl = !request.url.startsWith("http");

    if (isLoggedIn && isApiUrl) {
        request.headers.Authorization = `${store.getters["user/getToken"]}`;
    }

    return request;
});

apiClient.interceptors.response.use(
    (response) => {
        return response;
        //return Promise.resolve(response);
    },
    async (error) => {
        const turnOffNotify = (error.config.notifyError !== undefined && error.config.notifyError === false)

        if (error.code === 'ERR_NETWORK') {
            error['response'] = {
                data: {
                    message: 'network error!'
                }
            }

            if (!turnOffNotify) {
                notify('error', 'network error!')
            }
        }

        if (error.response && error.response.status === 401) {
            await store.dispatch("user/logout");
            await router.push({name: "login"});
        }

        return Promise.reject(error);
    }
);

const notify = (type, message) => {
    EventBus.emit('notify', {
        type: 'toast',
        options: {
            type: type,
        },
        message: message,
        action: false
    });
}


export default apiClient;
