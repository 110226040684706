<template>
  <v-row>
    <v-col cols="12" md="4" class="ma-auto">
      <v-img
        :width="100"
        class="ma-auto mb-4"
        :src="require('@/assets/logo.png')"
      />
      <v-card class="pa-4">
        <div class="text-h5 text-center mb-2">{{ $t('general.user_login') }}</div>
        <v-form ref="form" @submit="login">
          <v-text-field
            v-model="user.username"
            :label="$t('general.email')"
            prepend-icon="mdi-account-circle"
            :rules="[requiredRule, emailRule]"
          />
          <v-text-field
            v-model="user.password"
            :label="$t('general.password')"
            prepend-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            :rules="[requiredRule, passwordRule]"
          />
          <div class="d-flex justify-space-around mb-4">
            <router-link :to="{ name: 'forgot' }">{{ $t('general.forgot_password_question') }}</router-link>
            <router-link :to="{ name: 'register' }">{{ $t('general.sign_up') }}</router-link>
          </div>
          <div class="text-center">
            <v-btn
              type="submit"
              @click.prevent="login"
              :disabled="loading"
              :loading="loading"
              color="primary"
            >
              {{ $t('general.login') }}
            </v-btn>
          </div>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import AuthService from "@/services/AuthService";
import Validation from "@/plugins/Validation"

export default {
  name: "SignUp",
  created() {
    if (this.isLoggedIn) {
      this.$router.push({name: 'home'});
    }
  },
  mixins: [Validation],
  computed: {
    isLoggedIn() {
      return this.$store.getters['user/isLoggedIn'];
    },
  },
  data() {
    return {
      user: {
        username: null,
        password: null
      },
      loading: false,
      showPassword: false,
    }
  },
  methods: {
    async login() {
      const { valid } = await this.$refs.form.validate();

      if(!valid) {
        return false;
      }

      this.loading = true;

      AuthService.login(this.user)
        .then(
          (response) => {
            this.$store.dispatch("user/login", response.data.user);
            this.$router.push({name: "home"});
          },
          (error) => {
            this.$notify(error.response.data.message, {type: 'error'});
          }
        )
          .catch((error) => {
            this.$notify(error.response.data.message, {type: 'error'});
          })
        .finally(() => {
          this.loading = false;
        });
    },
  }
}
</script>
