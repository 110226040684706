<template>
  <v-app-bar
    v-show="isLoggedIn"
    flat
    absolute
    color="transparent"
  >
    <v-app-bar-title>
      <div class="header-logo d-flex align-center">
        <img :src="require('@/assets/logo.png')" alt="logo"/>
        <span class="ml-5">{{ $t('general.main_name') }}</span>
      </div>
    </v-app-bar-title>
    <template v-slot:append>
      <v-btn icon="mdi-magnify"></v-btn>

      <UserProjectsSelector />

      <div id="user-settings">
        <v-menu>
          <template v-slot:activator="{ props }">
            <v-avatar
                v-bind="props"
                class="cur-pointer"
                :image="require('@/assets/images/user.png')"
                size="46"
            ></v-avatar>
          </template>

          <v-list>
            <v-list-subheader>
              {{ getUserName }}
              <div v-if="balance > 0">
                <small class="text-success">Balance: {{ balance }} USD</small>
              </div>
            </v-list-subheader>
            <v-list-item :to="{ name: 'logout' }">
              <v-list-item-title>{{ $t('user.logout') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </template>
  </v-app-bar>
</template>

<script>
import { EventBus } from "@/utils/EventBus";
import UserProjectsSelector from "@/components/Layouts/Sections/UserProjectsSelector";
import StatisticWidgetService from "@/services/StatisticWidgetService";

export default {
  name: 'HeaderLayout',
  created() {
    this.bindEvents();
    this.getUserBalance();
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters['user/isLoggedIn'];
    },
    getUserName() {
      return this.$store.getters['user/getUser']['username'] || '';
    },
  },
  data() {
    return {
      balance: 0,
    }
  },
  watch: {
    isLoggedIn(value) {
      let routeName = this.$route.name;

      if (!value && (routeName !== 'login' || routeName !== 'logout') ) {
        this.$router.push({ name: 'login'});
      }
    }
  },
  methods: {
    bindEvents() {
      EventBus.on('update-user-balance', function(){
        this.getUserBalance();
      }.bind(this));
    },
    getUserBalance() {
      StatisticWidgetService.getUserBalance().then(response => {
        this.balance = response.data.balance;
      }, () => {
        this.balance = 0;
      });
    }
  },
  components: {
    UserProjectsSelector,
  }
}
</script>