export default {
  login: "Login",
  logout: "Logout",
  register: "Register",
  dashboard: "Dashboard",
  projects: "Projects",
  name: "Name",
  username: "Username",
  email: "Email",
  password: "Password",
  password_confirm: "Password Confirm",
  attachments: "Attachments",
  forgot_password: "Forgot Password",
  reset_password: "Reset Password",
  account_confirm: "Account Confirm",
  error: {
    duplicate_username: "The entered username is already in use. Please enter another username.",
    confirm_token_invalid: "Activation link is expired or user token is invalid.",
  },
  messages: {
    forgot_token_send: "Password reset link send to account email address successfully.",
    password_reset: "For reset {email} account password please fill current fields.",
    password_reset_successfully: "Account password reset successfully. Now you can login with new password.",
    account_activated: "Account activated successfully.",
  }
};
