import axios from "@/utils/Axios";

class StatisticWidgetService {
  getStatistic(url) {
    return axios.get(url);
  }

  getUserBalance() {
    return axios.get('/users/stats/balance');
  }
}

export default new StatisticWidgetService();