import DateRule from "@/plugins/Validation/Rules/Date";
import RequiredRule from "@/plugins/Validation/Rules/Required";
import EmailRule from "@/plugins/Validation/Rules/Email";
import PasswordRule from "@/plugins/Validation/Rules/Password";

export default {
  mixins: [
    DateRule,
    RequiredRule,
    EmailRule,
    PasswordRule
  ],
}
