<template>
  <v-row>
    <v-col cols="12" md="4" class="ma-auto">
      <v-img
          :width="100"
          class="ma-auto mb-4"
          :src="require('@/assets/logo.png')"
      />
      <v-card class="pa-4" v-show="!success">
        <v-form ref="form" @submit="register">
          <div class="text-h5 text-center mb-2">{{ $t('general.user_register') }}</div>
          <v-text-field
              v-model="user.username"
              :label="$t('general.email')"
              prepend-icon="mdi-account-circle"
              :rules="[requiredRule, emailRule]"
              autocomplete="one-time-code"
          />
          <v-text-field
              v-model="user.password"
              :label="$t('user.password')"
              prepend-icon="mdi-lock"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              :rules="[requiredRule, passwordRule]"
              autocomplete="one-time-code"
          />
          <v-text-field
              v-model="confirm"
              :label="$t('user.password_confirm')"
              prepend-icon="mdi-lock"
              :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPasswordConfirm ? 'text' : 'password'"
              @click:append="showPasswordConfirm = !showPasswordConfirm"
              :rules="[requiredRule, passwordRule, passwordConfirmRule(user.password)]"
              autocomplete="one-time-code"
          />
          <div class="d-flex justify-space-around mb-4">
            <router-link :to="{ name: 'forgot' }">{{ $t('general.forgot_password_question') }}</router-link>
            <router-link :to="{ name: 'login' }">{{ $t('general.sign_in') }}</router-link>
          </div>
          <div class="text-center">
            <v-btn
                type="submit"
                @click.prevent="register"
                :disabled="loading"
                :loading="loading"
                color="primary"
            >
              {{ $t('general.register') }}
            </v-btn>
          </div>
        </v-form>
      </v-card>
      <v-card v-show="success">
        <v-alert
            border="start"
            variant="tonal"
            color="success"
            :title="$t('general.register_successfully')"
        >
          {{ $t('general.register_successfully_message') }}
          <p class="text-center">
            <router-link :to="{ name: 'login' }">
              {{ $t('general.login') }}
            </router-link>
          </p>
        </v-alert>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import AuthService from "@/services/AuthService";
import Validation from "@/plugins/Validation"

export default {
  name: "SignUp",
  created() {
    if (this.isLoggedIn) {
      this.$router.push({name: 'home'});
    }
  },
  mixins: [Validation],
  computed: {
    isLoggedIn() {
      return this.$store.getters['user/isLoggedIn'];
    },
  },
  data() {
    return {
      success: false,
      user: {
        username: null,
        password: null
      },
      confirm: null,
      loading: false,
      showPassword: false,
      showPasswordConfirm: false,
    }
  },
  methods: {
    async register() {
      const formStatus = await this.$refs.form.validate();

      if (!formStatus.valid) {
        return false;
      }

      this.loading = true;

      AuthService.register(this.user).then(response => {
        this.success = (response);
      }, error => {
        let message = '';

        if (error.response.data.code === 11000) {
          message = this.$t('user.error.duplicate_username');
        } else {
          message = error.response.data.message;
        }

        this.$notify(message, {type: 'error'});
      }).finally(() => {
        this.loading = false;
      });
    },
  }
}
</script>
