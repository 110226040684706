<template>
  <div>
  <v-app-bar-nav-icon class="nav-burger-menu" variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
  <v-navigation-drawer
    v-if="isLoggedIn"
    :border="0"
    color="transparent"
    v-model="drawer"
  >
    <v-list density="compact" nav>
      <v-list-item
        v-for="(item, index) in menuItems"
        :key="'menu_item_' + index"
        :prepend-icon="item.icon"
        :title="$t(item.title)"
        :to="item.to"
      ></v-list-item>
    </v-list>
  </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "NavigationLayout",
  computed: {
    isLoggedIn() {
      return this.$store.getters['user/isLoggedIn'];
    },
  },
  data() {
    return {
      drawer: true,
      menuItems: [
        {
          icon: 'mdi-tablet-dashboard',
          title: 'dashboard.dashboard',
          to: { name: 'home' }
        },
        {
          icon: 'mdi-package-variant-closed',
          title: 'projects.projects',
          to: { name: 'projects' }
        },
        {
          icon: 'mdi-door',
          title: 'projects.rooms',
          to: { name: 'rooms' }
        },
        {
          icon: 'mdi-credit-card-outline',
          title: 'price.payments',
          to: { name: 'price' }
        },
        {
          icon: 'mdi-cog-outline',
          title: 'settings.settings',
          to: { name: 'settings' }
        },
      ]
    }
  }
}
</script>