export default {
  data() {
    return {
      futureDateRule: value => {
        if (!value)
          return true;

        const date  = new Date(value);
        const today = new Date();

        if (date <= today)
          return this.$t('form.error.date.selected_date_must_be_future');

        return true;
      },
    }
  },
  methods: {
    dateCompareRule(date, beforeDate = true) {
      return value => {
        if (!value || !date) {
          return true;
        }

        let date1 = new Date(value);
        let date2 = new Date(date);

        if (beforeDate && date1 >= date2)
          return this.$t('form.error.date.selected_date_must_be_before');

        if (!beforeDate && date1 <= date2)
          return this.$t('form.error.date.selected_date_must_be_after');

        return true;
      }
    }
  }
}