<template>
    <ToastNotify
      ref="toast"
    />
    <SnackbarNotify
      ref="snackbar"
    />
</template>

<script>
import { EventBus } from "@/utils/EventBus";
import ToastNotify from "./ToastNotify";
import SnackbarNotify from "./SnackbarNotify";

export default {
  name: "NotifyMain",
  mounted(){
    this.bindEvents();
  },
  methods: {
    bindEvents(){
      EventBus.on('notify', function(data){
        this.handleNotification(data);
      }.bind(this));
    },
    handleNotification(data) {
      if(data.type === 'toast') {
        this.$refs['toast'].addItem(data.message, data.options);
      } else {
        this.$refs['snackbar'].addItem(data.message, data.options, data.action);
      }
    }
  },
  components: {
    ToastNotify,
    SnackbarNotify,
  }
}
</script>