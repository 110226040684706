<template>
  <v-card id="line-chart-widget" class="chart-widget">
    <v-row>
      <v-col
          cols="12"
          class="filter-section"
      >
        <VueDatePicker
            range
            class="date-picker"
            :model-value="date"
            :enable-time-picker="false"
            @update:model-value="setChartData"
        />
        <div class="filter-range">
          <span
            v-for="(range, index) in filterRange"
            :key="'filter-range-item-' + index"
            :class="{ 'active': (filterDays === range.days)}"
            @click="setFilterRange(range.days)"
           >
            {{ range.label }}
          </span>
        </div>
      </v-col>
    </v-row>
    <v-row
      class="sections"
    >
      <span class="active">Connected Users</span>
    </v-row>
    <LineChart
        :options="options"
        :chartData="chartData"
    />
  </v-card>
</template>

<script>
import { LineChart } from 'vue-chart-3';
import { Chart, registerables } from "chart.js";
import LineChartWidgetService from "@/services/LineChartWidgetService";

Chart.register(...registerables);

export default {
  name: "LineChartWidget",
  created() {
    this.setFilterRange(7);
    this.getStatisticData();
  },
  computed: {
    selectedProject() {
      return this.$store.getters['project/getUserSelectedProject'];
    }
  },
  data() {
    return {
      date: null,
      filterDays: 7,
      options: {
        plugins: {
          legend: {
            display: false,
          }
        }
      },
      chartData: {
        labels: [],
        datasets: [
          {
            data: [],
            borderColor: '#5E35B1',
            backgroundColor: ['#77CEFF', '#0079AF', '#123E6B', '#97B0C4', '#A5C8ED'],
          },
        ],
      },
      filterRange: [
        { label: '1 W', days: 7 },
        { label: '2 W', days: 14 },
        { label: '1 M', days: 30 },
        { label: '3 M', days: 90 },
        { label: '6 M', days: 180 },
        { label: '1 Y', days: 365 },
      ],
    }
  },
  watch: {
    selectedProject() {
      this.setDate();
    },
  },
  methods: {
    setFilterRange(days) {
      this.filterDays = days;
      this.setDate();
    },
    setDate() {
      let startDate = new Date(new Date().setDate(new Date().getDate() - this.filterDays));
      let endDate = new Date();

      this.setChartData([startDate, endDate]);
    },
    getStatisticData(startDate, endDate) {
      if (!!this.selectedProject && !!this.selectedProject.id) {
        return LineChartWidgetService.getConnectedUsersStatistic(
            this.selectedProject.id,
            startDate,
            endDate
        )
      } else {
        return new Promise((resolve) => {
          resolve({
            data: []
          })
        })
      }
    },
    setChartData(value) {
      if(!value || !value[0] || !value[1]) {
        return false;
      }

      const setFormat = (date) => {
        let dd = String(date.getDate()).padStart(2, '0');
        let mm = String(date.getMonth() + 1).padStart(2, '0');
        let yy = date.getFullYear();

        return [mm, dd, yy].join('/');
      }

      this.date = [
        setFormat(value[0]),
        setFormat(value[1]),
      ];

      const startDate = value[0];
      const endDate = value[1];
      const datesArray = [];
      const numbersArray = [];

      this.getStatisticData(this.date[0], this.date[1]).then(response => {
        for (
          let date = startDate;
          date <= endDate;
          date.setDate(date.getDate() + 1)
        )
        {
          let newDate = new Date(date);
          datesArray.push(setFormat(newDate));

          const index = response.data.findIndex(x =>
            x._id.year === newDate.getFullYear() &&
            x._id.month === newDate.getMonth() + 1 &&
            x._id.day === date.getDate()
          );

          numbersArray.push(
            (index > -1)? response.data[index].total : 0
          );
        }

        this.chartData.labels = datesArray;
        this.chartData.datasets[0].data = numbersArray;
      }).catch(() => {
        //
      });
    },
  },
  components: {
    LineChart,
  },
}
</script>