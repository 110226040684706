<template>
  <span></span>
</template>

<script>
import AuthService from "@/services/AuthService";

export default {
  name: "LogoutAction",
  created() {
    this.logout();
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters['user/isLoggedIn'];
    }
  },
  methods: {
    logout() {
      AuthService.logout().then(() => {
        if(this.isLoggedIn) {
          this.$store.dispatch("user/logout");
        }
      }).finally(() => {
        this.$router.push({ name: 'login'});
      });
    },
  }
}
</script>
