import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import languages from './languages'
import vuetify from './utils/Vuetify'
import General from './components/General'
import Notification from './components/Notifications'

import { loadFonts } from './plugins/webfontloader'
import setupComponents from './utils/Components'

loadFonts()

const app = createApp(App);

setupComponents(app);

app.use(router)
   .use(store)
   .use(languages)
   .use(vuetify)
   .use(General)
   .use(Notification)
   .mount('#app')


