export default {
  settings: "Settings",
  page_title: "User Settings",
  emojis: "User Emojis",
  canvasTexts: "Canvas Texts",
  attachments: {
    submit: "Submit",
    reset: "Reset",
    choose_file: "Choose File",
    drag: "Drag",
    attachments: "Attachments",
    page_title: "User Emojis Settings",
    delete_successfully: "File delete successfully.",
  },
};
