<template>
  <div id="statistic-widget">
    <RouterLink :to="routerUrl">
    <v-icon
        class="widget-icon"
        :class="'bg-' + color"
    >
      {{ icon }}
    </v-icon>
    <v-card
        :title="title"
        :subtitle="subtitle"
        :loading="loading"
    >
      <div
          v-show="!loading"
          class="text-overline number"
      >
        {{ number }}
      </div>
    </v-card>
    </RouterLink>
  </div>

</template>

<script>
import StatisticWidgetService from "@/services/StatisticWidgetService";

export default {
  name: "StatisticWidget",
  props: ['icon', 'title', 'subtitle', 'color', 'url', 'fakeNumber', 'helper', 'routerUrl'],
  mounted() {
    this.fetchData();
  },
  data() {
    return {
      loading: true,
      number: 0,
    }
  },
  methods: {
    fetchData() {
      this.loading = true;
      // comment 3
      if(!this.url) {
        this.number = this.fakeNumber;
        this.loading = false;
        return false;
      }

      StatisticWidgetService.getStatistic(this.url).then(response => {
        if (!this.helper) {
          this.number = response.data.count;
        } else {
          this.number = this.helper(response.data);
        }
      }).finally(() => {
        this.loading = false;
      });
    }
  }
}
</script>