export default {
  save: "Save",
  update: "Update",
  cancel: "Cancel",
  confirm: "Confirm",
  actions: "Actions",
  error: {
    required: "This field is required.",
    email_format : "Email format is incorrect",
    password : {
      min_length : "You should enter at least {length} characters",
      match_password : "Password and confirmation are mismatch",
    },
    attachments: {
      file_format: "You must choose the true file format.",
      png_file_format: "You must choose the png file format.",
      txt_file_format: "You must choose the txt file format.",
      must_be_select_file: "You must choose a file for upload.",
      file_size: "Your selected file size must be smaller than {size} KB.",
    },
    date: {
      selected_date_must_be_future: "Please select future date.",
      selected_date_must_be_before: "Selected start date must be before end date.",
      selected_date_must_be_after: "Selected end date must be after start date.",
    }
  },
};
