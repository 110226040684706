import moment from 'moment';

export default {
  name: "DateHelper",
  methods: {
    calculateTotalMeetingsLength(value) {
      const totalSeconds = parseInt(value);
      const totalMinutes = Math.floor(totalSeconds / 60);
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      const seconds = totalSeconds % 60;

      if (hours > 0) return hours + ' H';
      if (minutes > 0) return minutes + ' Min';
      return seconds + ' Sec'
    },
    readableDate(date) {
      return moment(date, "YYYY-MM-DDTHH:mm:ss").format('YYYY-MM-DD HH:mm');
    }
  }
}