import { EventBus } from "@/utils/EventBus";

export default {
  install (Vue) {
    /**
     * options
     * type: [default, success, error, warning, info]
     * showActionBtn: [true, false] (snackbar)
     * actionBtnText: String (snackbar)
     * showIcon: [true, false] (toast)
     * timeout: Toast or Snackbar duration (ms)
     */

    Vue.config.globalProperties.$notify = (
      message,
      options = {},
      type = 'toast',
      action = false
    ) => {
      EventBus.emit('notify', {
        type: type,
        options: options,
        message: message,
        action: action
      });
    }
  }
}
