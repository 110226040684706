<template>
  <v-col
    v-if="items.length > 0 && showNotifies"
    cols="12"
    md="4"
    class="notify-items toast-notifies-type d-flex flex-column"
  >
    <TransitionGroup>
      <div
        v-for="(item, index) in items"
        :key="'toast_' + index"
        @click="closeToast(item)"
        class="toast-notify-item d-flex cur-pointer"
        :class="(icons[item.type])? 'bg-' + item.type : 'bg-primary'"
      >
        <v-icon
          v-show="item.showIcon"
          class="pr-3"
          dark
          large
        >
          mdi-{{ item.icon }}
        </v-icon>
        <div class="" :class="'type_' + item.type">
          {{ item.message }}
        </div>
      </div>
    </TransitionGroup>
  </v-col>
</template>

<script>
export default {
  name: "ToastNotify",
  data() {
    return {
      count: 0,
      items: [],
      showNotifies: true,
      options: {
        type: 'default',
        showIcon: true,
        timeout: 5000,
      },
      icons: {
        success: 'check-circle-outline',
        info: 'information-outline',
        warning: 'alert-outline',
        error: 'close-circle-outline',
        default: 'circle-double',
      }
    }
  },
  methods: {
    closeToast: function (item) {
      this.showNotifies = false;
      clearTimeout(item.timeout);
      let index = this.items.findIndex(x => x.id === item.id);
      this.items.splice(index, 1);
      this.$nextTick(() => {
        this.showNotifies = true;
      });
    },
    addItem: function (message, options) {
      let item = {
        id: (this.count++),
        message: message,
        icon: this.icons[options.type] || this.icons['default'],
        showIcon: (!options.showIcon && options.showIcon === false) ? options.showIcon : this.options.showIcon,
        type: options.type || this.options.type,
        timeout: options.timeout || this.options.timeout
      };

      item.timeout = setTimeout(() => {
        this.closeToast(item)
      }, item.timeout);
      this.items.push(item);
    },
  }
}
</script>
