export default {
  main_name: "Vidus",
  welcome_text: "Welcome to Vidus dashboard.",
  menu: {
    home: "Home",
    about: "About",
    register: "Register",
    login: "Login",
    dashboard: "Dashboard",
    payment: "Payment",
    logout: "Logout",
  },
  login: "Login",
  email: "Email",
  user_login: "User Login",
  user_register: "User Register",
  username: "Username",
  password: "Password",
  register: "Register",
  sign_in: "Sign In",
  sign_up: "Create Account",
  password_reset: "Password Reset",
  forgot_password: "Forgot Password",
  forgot_password_question: "Forgot Password?",
  forgot_password_reset: "Reset Password",
  forgot_password_reset_message: "Your account password reset link send to email successfully!",
  register_successfully: "Your account created successfully!",
  register_successfully_message: "Please click on sign in to redirect to login page.",
  close: "Close",
  loading_process: "loading...",
};
