import titleObject from "@/components/General/sections/title";
import breadcrumbsObject from "@/components/General/sections/breadcrumbs";

export default {
  install (Vue) {
    const general = {};

    titleObject(general);
    breadcrumbsObject(general);

    Vue.config.globalProperties.$general = general;
  }
}
