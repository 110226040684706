export default {
  project: "Project",
  projects: "Projects",
  projects_page_title: "User Projects",
  projects_register_page_title: "Create New Project",
  create_project: "Create Project",
  new_project: "New Project",
  project_create_success: "New project created successfully.",
  project_update_success: "Project updated successfully.",
  project_update_failed: "Project updated unsuccessfully.",
  name: "Project name",
  not_defined_app: "You don't have any project.<br />Please create one of them.",
  empty_room: "Selected project don't have any room.<br />Please create first meeting room of project.",
  without_project: "Not available any project.",
  select_project: "Select project",
  new: "Add Project",
  new_room: "Add Room",
  room: "Room",
  rooms: "Rooms",
  projects_room_page_title: "User Project Rooms",
  rooms_register_page_title: "Create New Room",
  project_overview_page_title: "Project Overview",
  room_details_page_title: "Room Details",
  room_name: "Room name",
  room_creator: "Room Creator",
  room_meeting_time: "Meeting time",
  room_start_time: "Start time",
  room_end_time: "End time",
  room_meeting_length: "Length",
  room_meeting_users_count: "Users count",
  room_meeting_moderator_is_present: "Moderator is present",
  room_details: "Details",
  join_room: "Join",
  app_id: "App ID",
  public_key: "Public Key",
  secret_key: "Secret Key",
  project_overview: "Overview",
  copied: "Copied!",
  reset_keys: "Reset Api Keys",
  project_not_find: "Can't find current project!",
  project_keys_reset: "Project keys reset successfully!",
  room_created_successfully: "New meeting room created successfully.",
  subscription: "Subscription",
  not_subscribed: "Not Subscribed",
  subscribed: "Subscribed",
  status: "Status",
  start_date: "Start date",
  end_date: "End date",
  remain_date: "Remained status",
  active: "Active",
  canceled: "Canceled",
  subscription_cancel: "Cancel Subscription",
  revert_unsubscription: "Revert UnSubscription",
  subscription_cancel_confirm_immediately: "Please confirm project subscription cancel immediately.",
  subscription_cancel_confirm_period: "Please confirm project subscription cancel at the end of current billing period.",
  cancel_immediately: "Immediately",
  cancel_period_end: "End of billing",
  revert_period_end: "Revert unsubscription",
  subscription_cancel_successfully_immediately: "Project subscription immediately canceled successfully.",
  subscription_cancel_successfully_period: "Project subscription canceled at the end of current billing period successfully.",
  unsubscription_revert_successfully_period: "Project unsubscription at the end of current billing period revert successfully.",
};
