import store from "@/store";
import { createRouter, createWebHistory } from 'vue-router'

import DashboardMain from "@/views/Dashboard/DashboardMain";
import SignIn from "@/views/Auth/SignIn";
import SignUp from "@/views/Auth/SignUp";
import Confirm from "@/views/Auth/ConfirmEmail";
import Forgot from "@/views/Auth/ForgotPassword";
import Reset from "@/views/Auth/ResetPassword";
import LogoutAction from "@/views/Auth/LogoutAction";

const routes = [
  {
    path: '/',
    name: 'home',
    component: DashboardMain,
    meta: {
      authenticated: true,
    }
  },
  {
    path: '/user/login',
    name: 'login',
    component: SignIn
  },
  {
    path: '/user/register',
    name: 'register',
    component: SignUp
  },
  {
    path: '/user/confirm-email',
    name: 'confirm',
    component: Confirm
  },
  {
    path: '/user/forgot',
    name: 'forgot',
    component: Forgot
  },
  {
    path: '/user/forgot/reset',
    name: 'reset',
    component: Reset
  },
  {
    path: '/user/logout',
    name: 'logout',
    component: LogoutAction,
    meta: {
      authenticated: true,
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '@/views/AboutPage.vue')
  },
  {
    path: '/price',
    name: 'price',
    component: () => import(/* webpackChunkName: "price" */ '@/views/Price/PlanPricePage.vue'),
    meta: {
      authenticated: true,
    }
  },
  {
    path: '/payment/success',
    name: 'paymentSuccess',
    component: () => import(/* webpackChunkName: "price" */ '@/views/Price/SuccessPayment.vue'),
    meta: {
      authenticated: true,
    }
  },
  {
    path: '/payment/cancel',
    name: 'paymentCancel',
    component: () => import(/* webpackChunkName: "price" */ '@/views/Price/CancelPayment.vue'),
    meta: {
      authenticated: true,
    }
  },
  {
    path: '/projects',
    name: 'projects',
    component: () => import(/* webpackChunkName: "dashboard/projects" */ '@/views/Projects/UserProjects.vue'),
    meta: {
      authenticated: true,
    }
  },
  {
    path: '/projects/:projectId',
    name: 'project.overview',
    component: () => import(/* webpackChunkName: "dashboard/projects" */ '@/views/Projects/ProjectOverview.vue'),
    meta: {
      authenticated: true,
    }
  },
  {
    path: '/projects/create',
    name: 'project.register',
    component: () => import(/* webpackChunkName: "dashboard/projects" */ '@/views/Projects/UserAddProject.vue'),
    meta: {
      authenticated: true,
    }
  },
  {
    path: '/projects/rooms',
    name: 'rooms',
    component: () => import(/* webpackChunkName: "dashboard/projects" */ '@/views/Projects/ProjectRooms.vue'),
    meta: {
      authenticated: true,
    }
  },
  {
    path: '/projects/rooms/:roomId/details',
    name: 'roomDetails',
    component: () => import(/* webpackChunkName: "dashboard/rooms" */ '@/views/Projects/RoomDetails.vue'),
    meta: {
      authenticated: true,
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/Settings/UserSettings.vue'),
    meta: {
      authenticated: true,
    }
  },
  {
    path: '/settings/emojis',
    name: 'settings.emojis',
    component: () => import(/* webpackChunkName: "settings/emojis" */ '@/views/Settings/UserEmojis.vue'),
    meta: {
      authenticated: true,
    }
  },
  {
    path: '/settings/canvasTexts',
    name: 'settings.canvasTexts',
    component: () => import(/* webpackChunkName: "settings/canvasText" */ '@/views/Settings/UserCanvasTexts.vue'),
    meta: {
      authenticated: true,
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to) => {
  const isLoggedIn = store.getters["user/isLoggedIn"];
  const authStatus = to.meta.authenticated || false;

  if (authStatus && !isLoggedIn) {
    return { name: 'login' }
  }
});


export default router
