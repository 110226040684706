<template>
  <v-app id="main_content" class="main-content">
    <Header />
    <v-layout id="main-section">
      <Main />
      <Navigation />
    </v-layout>
    <Footer />
    <NotifyMain />
  </v-app>
</template>


<script>
import Header from "@/components/Layouts/HeaderLayout";
import Main from "@/components/Layouts/MainLayout";
import Navigation from "@/components/Layouts/NavigationLayout";
import Footer from "@/components/Layouts/FooterLayout";
import NotifyMain from "@/components/Notifications/NotifyMain";

export default {
  name: 'App',
  data: () => ({
    //
  }),
  components: {
    Header,
    Main,
    Navigation,
    Footer,
    NotifyMain,
  }
}
</script>

<style lang="scss">
  @import "@mdi/font/css/materialdesignicons.css";
  @import "vuetify/styles";
  @import "./sass/Theme.scss";
</style>
