import general from "@/languages/en-GB/general";
import user from "@/languages/en-GB/user";
import form from "@/languages/en-GB/form";
import price from "@/languages/en-GB/price";
import projects from "@/languages/en-GB/projects";
import dashboard from "@/languages/en-GB/dashboard";
import settings from "@/languages/en-GB/settings";

export default {
  general: general,
  user: user,
  form: form,
  price: price,
  projects: projects,
  dashboard: dashboard,
  settings: settings,
};
