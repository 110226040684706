import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import createMutationsSharer from "vuex-shared-mutations";

import user from "./modules/user/user";
import project from "./modules/project/project";

export default createStore({
  namespaced: true,
  plugins: [
    createPersistedState({
      key: process.env.VUE_APP_NAME,
    }),
    createMutationsSharer({
      predicate: [
        'user/SET_USER',

      ]
    })
  ],
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    project,
  },
});
