import axios from "@/utils/Axios";

class AuthService {
  login(data) {
    return axios.post("login", data);
  }

  logout() {
    return axios.get("logout");
  }

  register(data) {
    return axios.post("signup", data);
  }

  confirm(params) {
    return axios.get("/confirm-email", { params: params });
  }

  forgot(params) {
    return axios.get("/users/password/reset", { params: params });
  }

  forgotCheck(data) {
    return axios.post("/users/password/reset/check", data);
  }

  forgotReset(data) {
    return axios.post("/users/password/reset", data);
  }
}

export default new AuthService();