import { EventBus } from "@/utils/EventBus";

/*
 * Set Breadcrumbs : [] - Options
 * title: breadcrumb title
 * to: vue route params
 * href: url
 */

export default function(general) {
  general.setBreadcrumbs = (breadcrumbs = []) => {
    EventBus.emit('set-main-header-breadcrumbs', breadcrumbs);
  }
}