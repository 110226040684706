import axios from "@/utils/Axios";

class ProjectService {
  getUserProjects(params) {
    return axios.get("user/projects", { params: params });
  }

  getUserProject(projectId) {
    return axios.get("user/projects/" + projectId);
  }

  updateUserProject(projectId, data) {
    return axios.put("user/projects/" + projectId, data);
  }

  createUserProject(data) {
    return axios.post("user/projects", data);
  }

  resetUserProjectKeys(projectId) {
    return axios.put("user/projects/" + projectId + "/reset");
  }

  cancelProjectSubscriptionImmediately(projectId) {
    return axios.post("user/projects/" + projectId + "/unsubscribe/immediately");
  }

  cancelProjectSubscriptionPeriod(projectId) {
    return axios.post("user/projects/" + projectId + "/unsubscribe/period");
  }

  revertProjectUnSubscriptionPeriod(projectId) {
    return axios.post("user/projects/" + projectId + "/unsubscribe/revert");
  }
}

export default new ProjectService();