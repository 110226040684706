export default {
  dashboard: "Dashboard",
  attachments: {
    submit: "Submit",
    reset: "Reset",
    choose_file: "Choose File",
    drag: "Drag",
    attachments: "Attachments",
  },
};
