<template>
  <div id="main-header-layout" v-show="isLoggedIn">
    <h2 class="text-overline">
      {{ title }}
    </h2>
    <v-breadcrumbs :items="breadcrumbs" class="text-overline">
      <template v-slot:prepend>
        <v-icon size="small" icon="mdi-home"></v-icon>
      </template>
    </v-breadcrumbs>
  </div>
</template>

<script>
import { EventBus } from "@/utils/EventBus";

export default {
  name: "MainHeaderLayout",
  created() {
    this.bindEvents();
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters['user/isLoggedIn'];
    },
  },
  data() {
    return {
      title: null,
      breadcrumbs: [],
    }
  },
  watch: {
    '$route': {
      handler: function() {
        this.title = this.$t('dashboard.dashboard');
        this.breadcrumbs = [{
          title: this.$t('dashboard.dashboard'),
          to: { name: 'home' }
        }];
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    bindEvents() {
      EventBus.on('set-main-header-title', function(data){
        this.title = data.title;
      }.bind(this));

      EventBus.on('set-main-header-breadcrumbs', function(data){
        data.unshift({
          title: this.$t('dashboard.dashboard'),
          to: { name: 'home' }
        });

        this.breadcrumbs = data;
      }.bind(this));
    }
  }
}
</script>