<template>
  <v-row>
    <v-col cols="12" md="3">
      <!-- TODO implement router-url -->
      <StatisticWidget
          router-url="/"
          url="/projects/stats/user/count"
          icon="mdi-account-group"
          color="deep-purple"
          title="Users"
          subtitle="Account subscribed users"
      />
    </v-col>
    <v-col cols="12" md="3">
      <StatisticWidget
          router-url="projects/rooms"
          url="/rooms/stats/count"
          icon="mdi-door"
          color="light-green"
          title="Rooms"
          subtitle="Account created rooms"
      />
    </v-col>
    <v-col cols="12" md="3">
      <!-- TODO implement router-url -->
      <StatisticWidget
          router-url="/"
          url="/rooms/stats/length/total"
          icon="mdi-alarm"
          color="amber"
          title="Length"
          subtitle="Conferences Total Length"
          :helper="meetingLength"
      />
    </v-col>
    <v-col cols="12" md="3">
      <StatisticWidget
          router-url="projects"
          url="/projects/stats/count"
          icon="mdi-package-variant-closed"
          color="deep-orange"
          title="Projects"
          subtitle="User created projects"
      />
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <LineChartWidget
      />
    </v-col>
  </v-row>
</template>

<script>
import StatisticWidget from "@/views/Dashboard/StatisticWidget";
import LineChartWidget from "@/views/Dashboard/LineChartWidget";
import DateHelper from "@/helpers/DateHelper";

export default {
  name: "DashboardMain",
  mixins: [DateHelper],
  created() {
    this.$general.setPageTitle(this.$t('dashboard.dashboard'));
    this.$general.setHeaderTitle(this.$t('dashboard.dashboard'));
    this.$general.setBreadcrumbs([]);
  },
  methods: {
    meetingLength(value) {
      return this.calculateTotalMeetingsLength(value.total)
    }
  },
  components: {
    StatisticWidget,
    LineChartWidget,
  }
}
</script>
