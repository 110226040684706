<template>
  <v-row>
    <v-col cols="12" md="4" class="ma-auto">
      <v-img
        :width="100"
        class="ma-auto mb-4"
        :src="require('@/assets/logo.png')"
      />
      <v-card class="pa-4" v-show="!success">
        <div class="text-h5">{{ $t('general.forgot_password') }}</div>
        <v-form ref="form">
          <v-text-field
            v-model="user.email"
            :label="$t('general.email')"
            prepend-icon="mdi-account-circle"
            :rules="[requiredRule, emailRule]"
          />
          <div class="d-flex justify-space-around mb-4">
            <router-link :to="{ name: 'register' }">{{ $t('general.sign_up') }}</router-link>
            <router-link :to="{ name: 'login' }">{{ $t('general.sign_in') }}</router-link>
          </div>
          <div class="text-center">
            <v-btn
              @click="reset"
              :disabled="loading"
              :loading="loading"
              color="primary"
            >
              {{ $t('general.forgot_password_reset') }}
            </v-btn>
          </div>
        </v-form>
      </v-card>
      <v-card v-show="success">
        <v-alert
            border="start"
            variant="tonal"
            color="success"
            :title="$t('user.forgot_password')"
        >
          {{ $t('user.messages.forgot_token_send') }}
        </v-alert>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Validation from "@/plugins/Validation";
import AuthService from "@/services/AuthService";

export default {
  name: "ForgotPassword",
  mixins:[Validation],
  data() {
    return {
      user: {
        email: null,
      },
      loading: false,
      success: false,
    }
  },
  methods: {
    async reset() {
      const {valid} = await this.$refs.form.validate();

      if (!valid) {
        return false;
      }

      this.loading = true;

      AuthService.forgot({
        email: this.user.email
      }).then(() => {
        this.success = true;
      }, error => {
        this.$notify(error.response.data.message, {type: 'error'});
      }).finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>
