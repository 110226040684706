import { createI18n } from "vue3-i18n";
import en_GB from "@/languages/en-GB/en_GB";

const messages = {
  en: en_GB,
};

const i18n = createI18n({
  locale: "en",
  messages: messages,
});

export default i18n;
