<template>
  <v-row>
    <v-col cols="12" md="4" class="ma-auto">
      <v-img
        :width="100"
        class="ma-auto mb-4"
        :src="require('@/assets/logo.png')"
      />
      <v-card v-show="success">
        <v-alert
            border="start"
            variant="tonal"
            color="success"
            :title="$t('user.account_confirm')"
        >
          {{ $t('user.messages.account_activated') }}
          <p class="text-center">
            <router-link :to="{ name: 'login' }">
              {{ $t('general.login') }}
            </router-link>
          </p>
        </v-alert>
      </v-card>
      <v-card v-show="!success && !loading">
        <v-alert
            border="start"
            variant="tonal"
            color="error"
            :title="$t('user.account_confirm')"
        >
          {{ $t('user.user.error.confirm_token_invalid') }}
        </v-alert>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Validation from "@/plugins/Validation";
import AuthService from "@/services/AuthService";

export default {
  name: "ForgotPassword",
  mixins:[Validation],
  computed: {
    email() {
      return this.$route.query?.email;
    },
    token() {
      return this.$route.query?.token;
    }
  },
  mounted() {
    this.check();
  },
  data() {
    return {
      loading: false,
      success: false,
    }
  },
  methods: {
    check() {
      if (!this.email || !this.token) {
        this.$router.push({
          name: 'login'
        });
      }

      this.loading = true;

      AuthService.confirm({
        email: this.email,
        token: this.token,
      }).then(() => {
        this.success = true;
      }, error => {
        this.$route.push({name: 'login'});
        this.$notify(error.response.data.message, {type: 'error'});
      }).finally(() => {
        this.loading = false;
      })
    },
  }
}
</script>
