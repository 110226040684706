<template>
  <v-footer
    class="justify-center"
    color="transparent"
  >
    <p class="text-center text-overline">
      Copyright 2024. All right reserved.
    </p>
  </v-footer>
</template>

<script>
export default {
  name: 'FooterLayout',
}
</script>
