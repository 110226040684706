export default {
  data() {
    return {
      passwordRule: value => value.length >= 6 || this.$t('form.error.password.min_length', { length: 6 }),
    }
  },
  methods: {
    passwordConfirmRule(match_field) {
      return value => {
        if((value === null || value === '') && (match_field === null || match_field === '')) {
          return true;
        }

        if(value !== match_field) {
          return this.$t('form.error.password.match_password');
        }

        return true;
      }
    }
  }
}